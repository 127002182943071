require('../css/backoffice.scss');

$(document).ready(function(){

    (function () {
        $(".nav-icon").click(function () {
            return $("div").toggleClass("cross");
        });
    }).call(this);

    if ($('form .form-group').length > 0){

        $("form input, form textarea").not("[type='checkbox']").each(function() {
            if( $(this).val().length > 0 ) {
                $(this).parent().addClass('is_valid');
            } else {
                $(this).parent().removeClass('focused');
            }

            $("<span class='bar'></span>").insertAfter(this);

            var autofocusAttr = $(this).attr('autofocus');
            if (typeof autofocusAttr !== typeof undefined && autofocusAttr !== false) {
                $(this).parent().addClass('focused');
            }
        });

        $("input, textarea").on('focus blur', function(){
            $(this).parent().addClass('focused');
        });


        $('form input, form textarea').blur(function(){
            if( $(this).val().length === 0 ) {
                $(this).parent().removeClass('is_valid');
                $(this).parent().removeClass('focused');
            }
        });

    }


    /* init Parallax ---------------- */
    var parallaxElements = $('.parallax'),
        parallaxQuantity = parallaxElements.length;

    if (parallaxElements.length > 0) {
        $(window).on('scroll', function () {
            var scroll = $(window).scrollTop();
            window.requestAnimationFrame(function () {
                for (var i = 0; i < parallaxQuantity; i++) {
                    var currentElement = parallaxElements.eq(i);
                    var scrolled = $(window).scrollTop();

                    currentElement.css({
                        'transform': 'translate3d(0,' + scrolled * +0.3 + 'px, 0)'
                    });
                }
            });

            if (scroll > 50) {
                $(".parallax h1").addClass('hidden');
            } else {
                $(".parallax h1").removeClass('hidden');
            }

        });
    }

});